const moment = require('moment')

export default (context, inject) => {
  const apiv2 = {
    async request(obj, verify = false, surpress = false, loader = true, passthrough = false) {
      obj.url = `${window.location.origin}/api/v2/${obj.headers.path}`
      delete obj.headers.path
      if(process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      if(context.store.state.env) {
        obj.headers.env = context.store.state.env
      } else if(process.env.API_ENV) {
        obj.headers.env = process.env.API_ENV
      } 
      obj.headers.client_type = context.store.state.client
      if(context.store.state.deviceId) {
        obj.headers.device_id = context.store.state.deviceId
      }
      if (loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          if (loader) {
            context.store.commit('setLoading', false)
          }
          return res.data
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if(passthrough == true) {
            if (err.message) {
              console.error('v2', err.message)
            }
            return err?.response?.data ?? ''
          } else if(surpress === false) {
            if(err?.response?.data && Array.isArray(err.response.data)) {
              if(typeof err.response.data[0] === 'string') {
                context.store.commit('setErrors', err.response.data)
              } else {
                let errors = []
                err.response.data.map(e => {
                  errors.push(e.message)
                })
                context.store.commit('setErrors', errors)
              }
            } else if(err?.response?.data?.message) {
              if (err.response.data.message.includes('Could not load shopping basket')) {
                console.error('v2', err.response.data.message)
                context.store.commit('setOrder', null)
              } else if (!obj.url.includes('upsells')) { // Hide upsells 'Not Found' error message
                context.store.commit('setErrors', [err.response.data.message])
              }
            } else {
              context.store.commit('setErrors', [
                'Connection issue. Please try again.'
              ])
            }
          }
          return null
        })
    },
    // V2 Create Cart
    async createCart(handoff_time = null) {
      const request = {
        method: 'post',
        headers: {
          path: 'carts'
        },
        data: {
          menu_id: context.store.state.location.menu_id,
        }
      }

      if (handoff_time) {
        request.data.time = handoff_time
      }

      if (context.store.state.token) {
        request.headers.token = context.store.state.token.ordering.access.token
      }
      
      let order = await this.request(request)
      if (order) {
        context.store.commit('setOrder', order)
      }

      return order
    },
    // Cart Referral
    async applyCartReferral(payload) {
      if(!context.store.state.order) {
        await this.createCart()
      }

      if (context.store.state.order.id) {
        const response = await this.request({
          method: 'put',
          headers: {
            path: `carts/${context.store.state.order.id}/referral`,
          },
          data: payload
        })
        
        if (response) {
          context.store.commit('setErrors', response)
          return false
        } else {
          return true
        }
      }

      return false
    },
  }

  inject('apiv2', apiv2)
  context.$apiV2 = apiv2
}
