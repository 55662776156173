//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data() {
    return {
      orders: null
    }
  },
  computed: {
    ...mapState([ 'banner', 'user' ])
  }
}
