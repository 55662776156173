//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'order', 'location', 'user',  ]),
    ...mapState({
      type: state => state.customer.type
    }),
    wanted() {
      return moment(this.order.time.wanted || this.order.time.earliest, 'YYYY-MM-DD HH:mm').calendar()
    },
    shouldShowQuickSells() {
      return this.upsells && this.upsells.length && this.shouldShowQuickAdds
    },
    orderAheadAvailable() {
      return this.location?.services?.advancedays > 0
    },
  },
  data() {
    return {
      show: false,
      orderPage: (this.$route.path.replace(/\//g,'') == 'order'),
      upsells: [],
      shouldShowQuickAdds: true,
      mode: 'asap',
      time: '',
      showChangeTime: false
    }
  },
  async mounted() {
    this.show = true

    this.getUpsells()
  },
  methods: {
    goToMenu() {
      this.hideCart()
      this.$router.push('/order/')
    },
    hideCart() {
      this.show = false
      setTimeout(function() {
        this.$store.commit('setShowCart', false)
      }.bind(this), 500)
    },
    startOrder() {
      this.hideCart()
      const isLoggedInOrGuest = this.user || this.type == 'guest'
      if (isLoggedInOrGuest && this.order && this.order.products && this.order.products.length) {
        this.$router.push('/order/checkout')
      } else if (!this.user && !this.type) {
        this.$router.push('/account/signin')
      } else {
        this.$router.push('/order/')
      }
    },
    async setHandoff(handoff) {
      await this.$api.setHandoff(handoff)
    },
    async getUpsells() {
      try {
        this.upsells = await this.$api.getUpsellsForCart()
      } catch (e) {
        shouldShowQuickAdds = false
        this.upsells = []
      }
    },
    async confirmTime() {
      if (this.mode === 'asap') {
        await this.setASAP()
      } else {
        if (this.time) {
          await this.$api.setTime(this.time)
        }
      }

      this.showChangeTime = false
    },
    async setASAP() {
      if(this.order.time.mode != 'asap') {
        await this.$api.deleteTime()
      }
      this.mode = 'asap'
    },
    setTime(time) {
      this.time = time
    }
  }
}
