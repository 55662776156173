//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'

import { mapState } from "vuex";

export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    upsells: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
            }
          },
        ],
      }
    }
  },
  computed: {
    ...mapState(["menu"]),
    upsellsWithImages() {
      return this.upsells.map(upsell => {
        const allProducts = this.menu.categories.reduce((acc, category) => {
          return acc.concat(category.products);
        }, []);

        const product = allProducts.find(
          product => product.name === upsell.name
        );

        return {
          ...product,
          imageUrl:
            "https://olo-images-live.imgix.net/" + product.images[0].filename,
        };
      });
    }
  },
  methods: {
    async addUpsell(uid) {
      const added = await this.$api.addUpsellItem(uid, 1)
      if (added) {
        this.$store.commit('setOrder', added)
      }

      this.$emit("get-upsells");
    },
  }
};
